export const products = [
    {
        itemNumber: 'gk01',
        itemName: `gk01.Speisen und Getränke Kalk 100`,
        category: `single`,
        update: `N`,
        netto: '299,00',
        brutto: '359,00',
        bewharung: `€`,
        salePreis: '359,00',
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2007`,
        anwendung: [
            `imbiss`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk02',
        itemName: `gk02.CateringEventManagerKalk100`,
        category: `single`,
        update: ``,
        netto: '249,00',
        brutto: `299,00`,
        bewharung: `€`,
        salePreis: `259.49`,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `foodtruck`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk03',
        itemName: `gk03.CateringEventFixKalkulator`,
        category: `single`,
        update: `N`,
        netto: '209,00',
        brutto: `249,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2007`,
        anwendung: [
            `foodtruck`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk04',
        itemName: `gk04.SpeGetKalk100+CatEvtManagerKalk100+CatEvtFixKalk`,
        category: `kombo`,
        update: `N`,
        netto: '499,00',
        brutto: `599,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `event`,
            `foodtruck`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk05',
        itemName: `gk05.SpeisenGetränkeKalk100+CatEvtManagerKalk100`,
        category: `kombo`,
        update: `N`,
        netto: '459,00',
        brutto: `549,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `restaurant`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk06',
        itemName: `gk06.SpeisenGetränkeKalk100+CatEvtFixKalk`,
        category: `kombo`,
        update: `N`,
        netto: '419,00',
        brutto: `499,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2007`,
        anwendung: [
            `kantine`,
            `restaurant`,
            `imbiss`,
            `catering`,
            `foodtruck`,
            `event`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk07',
        itemName: `gk07.CateringEventManagerKalk100+CateringEventFixKalk`,
        category: `kombo`,
        update: `N`,
        netto: '379,00',
        brutto: `449,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `kantine`,
            `restaurant`,
            `imbiss`,
            `catering`,
            `foodtruck`,
            `event`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk08',
        itemName: `gk08.Dienstplaner`,
        category: `single`,
        update: `N`,
        netto: '83,00',
        brutto: `99,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `kantine`,
            `restaurant`,
            `imbiss`,
            `catering`,
            `foodtruck`,
            `event`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk09',
        itemName: `gk09.Speisenplaner Datenbank`,
        category: `single`,
        update: `N`,
        netto: '209,00',
        brutto: `249,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2007`,
        anwendung: [
            `kantine`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk10',
        itemName: `gk10.BruchVerlustKontrolle`,
        category: `single`,
        update: `N`,
        netto: '99,00',
        brutto: `119,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `catering`,
            `imbiss`,
            `restaurant`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk11',
        itemName: `gk11.Bankett Fix Kalkulator`,
        category: `single`,
        update: `N`,
        netto: '108,00',
        brutto: `129,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2013`,
        anwendung: [
            `kantine`,
            `restaurant`,
            `imbiss`,
            `catering`,
            `foodtruck`,
            `event`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk16',
        itemName: `gk16.SpeisenGetränkeKalk500+CateringBankettKalk10`,
        category: `kombo`,
        update: `N`,
        netto: '589,00',
        brutto: `699,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2010`,
        anwendung: [
            `catering`,
            `event`,
            `kantine`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk17',
        itemName: `gk17.SpeisenGetränkeKalk500+CatEvtPlanKalk160`,
        category: `kombo`,
        update: `N`,
        netto: '759,00',
        brutto: `899,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2010`,
        anwendung: [
            `catering`,
            `event`,
            `kantine`,
            `restaurant`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
    {
        itemNumber: 'gk18',
        itemName: `gk18.SpeGetKalk500+CatEvtPlanKalk160+CatEvtFixKalk10`,
        category: `kombo`,
        update: `N`,
        netto: '839,00',
        brutto: `999,00`,
        bewharung: `€`,
        salePreis: ``,
        savings: `15%`,
        saleExpires: `19.02.2023`,
        description: `Bio Pilze`,
        systemRequirements: `2010`,
        anwendung: [
            `kantine`,
        ],
        image: ``,
        video: ``,
        inhalt: [
            `inhalt`,
        ],
        leistung: [
            `vieles mehr`,
        ],
    },
];