import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import { Row, Col } from "react-bootstrap";
import { programm } from "../data/programm";
// import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const AnwendungPageTemplate = ({
    title,
    items,
    description,
    mainImage,
    image1,
    content,
    contentComponent,
}) => {
    // const PageContent = contentComponent || Content;
    const anwImg = getImage(mainImage) || mainImage;
    // const img2 = getImage(image1.image) || image1.image;
    // const alt = `Gastrokalk Programm Anwendung`;

    return (
        <>
            <Row>
                <Col lg={8} className="pb-2">
                    <h1 className="page-title title-border">
                        {title}
                    </h1>
                </Col>
            </Row>
       
            <Row>
                <Col className="col-lg-8 offset-lg-2">

                    <section class="anwendung-container">
                        <div class="anwendung-image">
                            <FullWidthImage img={anwImg} alt={title} />
                            <div className="anwendung-text">
                                <div className="anwendung-inner-text pb-4">
                                    <div className="accent-border-bottom-orange">{description}</div>
                                    <ul>
                                        {items.map((item, index) => (
                                                <li key={index}><Link to={`/products/${item}?v=anw${title}`} className="anwendung-link">{item}</Link></li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    {/* <PreviewCompatibleImage imageInfo={mainImage} /> prvw */}

                    <hr className=" border-primary border-2 opacity-50" />

                    {/* { content && 
                        (
                            <PageContent className="anwendung-items" content={content} />
                        )
                    } */}

                    
                </Col>
            </Row>
        </>
    );
};

const AnwendungPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const location= "anwendung"

    return (
        <Layout location={location} currentPage={frontmatter.title}>
            <AnwendungPageTemplate
                title={frontmatter.title}
                items={frontmatter.items}
                description={frontmatter.description}
                mainImage={frontmatter.mainImage}
                image1={frontmatter.image1}
                // contentComponent={HTMLContent}
                // content={data.markdownRemark.html}
            />
        </Layout>
    );
};

AnwendungPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            content: PropTypes.string,
            frontmatter: PropTypes.object,
        }),
    }),
};

export default AnwendungPage;

export const Head = () => <Seo title="Anwendung Page" />

export const anwendungPageQuery = graphql`
  query AnwendungPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        items
        description
        mainImage {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        image1 {
            alt
            image {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
        }
      }
    }
  }
`;
